export const community = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
    },
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
    },
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
    },
  },
];

export const features = {
  items: [
    {
      icon: 'fas fa-sign-in-alt',
      title: 'Sign Up',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    {
      icon: 'fas fa-calendar-week',
      title: 'Create Events',
      subtitle:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    {
      icon: 'fas fa-share-square',
      title: 'Share memories',
      subtitle:
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
  ],
  people: [
    {
      authorPhoto: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
    },
    {
      authorPhoto: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
    },
    {
      authorPhoto: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
      },
    },
    {
      authorPhoto: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x',
      },
    },
    {
      authorPhoto: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x',
      },
    },
    {
      authorPhoto: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
      },
    },
  ],
};

export const pricings = [
  {
    title: 'Standard License',
    subtitle: 'A pay-once license, just for you',
    price: '$49',
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
  {
    title: 'Extended License',
    subtitle: 'A pay-once license, just for you',
    price: '$79',
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: true,
  },
  {
    title: 'Standard License',
    subtitle: 'A pay-once license, just for you',
    price: '$49',
    priceSuffix: ' / MO',
    features: [
      'Rich, responsive landing pages',
      '100+ styled components',
      'Flexible, simple license',
      'Speedy build tooling',
      '6 months free support included',
    ],
    disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
];
